.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

table {
  font-size: small;
}

.divider {
  background-color: lightblue;
  font-weight: bold;
}

.subdivider {
  background-color: yellow;
  font-weight: bold;
}

.left {
  text-align: left;
}

th,
td {
  white-space: nowrap;
}

/* div.dataTables_wrapper {
  margin: 0 auto;
}

table.dataTable td {
  font-size: 0.8em;
}

table.dataTable tr.dtrg-level-0 td {
  font-size: 0.9em;
}

table.dataTable th {
  font-size: 0.8em;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 0px 5px 0px 5px;
}

table.dataTable tbody th+th,
table.dataTable tbody td+td {
  padding: 0px 5px 0px 5px;
  text-align: center;
}

table.dataTable tr.dtrg-level-0 th {
  font-size: 0.9em;
}

div.dataTables_wrapper div.dataTables_info {
  padding-top: 0px;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  padding: 0px 0px 0px 0px;
}

DataTables_Table_0_wrapper {
  padding: 0;
} */

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 240px;
  /* background-color: black; */
  /* color: #fff; */
  text-align: left;
  padding: 5px 0;
  border-radius: 2px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* a:link {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: white;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: lightgreen;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: yellow;
  background-color: transparent;
  text-decoration: underline;
} */

.stickToBottom {
  width: '100%';
  position: 'fixed';
  bottom: 0;
}
